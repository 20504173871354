import React from "react";
import { useEnvConfig } from "../hooks/useEnvConfig";
import { Alert } from "@mui/material";

export function EnvironmentBanner() {
  const { env } = useEnvConfig();

  if (env === "Production") return;

  document.title = `Beca B2C Demo (${env})`;

  return <Alert severity="warning">You are running in {env} environment</Alert>;
}
