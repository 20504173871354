import React from "react";

import { PageLayout } from "./components/PageLayout";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  MsalProvider,
} from "@azure/msal-react";
import "./App.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { Alert, Stack, Typography } from "@mui/material";
import { InvitationController } from "./components/InvitationController";
import { ClaimsTable } from "./components/ClaimsTable";
import {
  AccessTokenReaderController,
  IdTokenReaderController,
} from "./components/TokenReaderController";
import { AuthVerificationController } from "./components/AuthVerificationController";
import { useAuthConfig } from "./hooks/useAuthConfig";
import { PolicyProvider } from "./context/policyContext";
import { PolicySwitchController } from "./components/PolicySwitchController";
import { EnvironmentBanner } from "./components/EnvironmentBanner";

export default function App() {
  const msalConfig = useAuthConfig();

  return (
    <PolicyProvider>
      <MsalProvider instance={new PublicClientApplication(msalConfig)}>
        <PageLayout>
          <div className="App">
            <Stack gap={1}>
              <EnvironmentBanner />
              <Alert severity="info">
                This is a demo application to showcase the capabilities of Azure
                AD B2C as well as testing the different flows
              </Alert>
              <PolicySwitchController />
              <AuthenticatedTemplate>
                <Main />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Typography variant="h5">
                  Please sign-in to see your profile information.
                </Typography>
              </UnauthenticatedTemplate>
            </Stack>
          </div>
        </PageLayout>
      </MsalProvider>
    </PolicyProvider>
  );
}

function Main() {
  const { accounts } = useMsal();

  return (
    <Stack gap={6}>
      <Typography variant="h5">Welcome {accounts[0]?.name}</Typography>
      <AuthVerificationController />
      <InvitationController />
      <AccessTokenReaderController />
      <IdTokenReaderController />
      <ClaimsTable />
    </Stack>
  );
}
