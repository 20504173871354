import { Button, Stack, TextareaAutosize, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthFlow } from "../hooks/useAuthFlow";

export function AccessTokenReaderController() {
  const [accessToken, setAccessToken] = useState<string>("");
  const { getToken } = useAuthFlow();

  useEffect(() => {
    loadToken();
  }, []);

  return (
    <Stack alignItems="flex-start" gap={1}>
      <Typography variant="h4">Check out your AccessToken</Typography>
      <Stack direction="row" gap={1}>
        {accessToken && (
          <Button
            variant="contained"
            onClick={() => window.navigator.clipboard.writeText(accessToken)}
          >
            Copy Access Token
          </Button>
        )}
      </Stack>
      {accessToken && (
        <TextareaAutosize
          maxRows={4}
          defaultValue={accessToken}
          style={{ width: "100%" }}
        />
      )}
    </Stack>
  );

  function loadToken() {
    getToken()?.then((token) => setAccessToken(token.accessToken));
  }
}

export function IdTokenReaderController() {
  const [idToken, setIdToken] = useState<string>("");
  const { getToken } = useAuthFlow();

  useEffect(() => {
    loadToken();
  }, []);

  return (
    <Stack alignItems="flex-start" gap={1}>
      <Typography variant="h4">Check out your ID Token</Typography>
      <Stack direction="row" gap={1}>
        {idToken && (
          <Button
            variant="contained"
            onClick={() => window.navigator.clipboard.writeText(idToken)}
          >
            Copy ID Token
          </Button>
        )}
      </Stack>
      {idToken && (
        <TextareaAutosize
          maxRows={4}
          defaultValue={idToken}
          style={{ width: "100%" }}
        />
      )}
    </Stack>
  );

  function loadToken() {
    getToken()?.then((token) => setIdToken(token.idToken));
  }
}
