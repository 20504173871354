import React, { createContext, useEffect, useState } from "react";

export type PolicyType = "SIGNUP_SIGNIN" | "PROFILE_EDIT" | "PASSWORD_RESET";

export type PolicyGroup = "BEYON_STANDARD" | "BEYON_NZGD";

export type PolicyStateType = {
  policyGroup: string;
  setPolicyGroup: (policyType: PolicyGroup) => void;
  getPolicy: (policyType: PolicyType) => string;
};

// Create the provider component
export function PolicyProvider({ children }) {
  const [policyGroup, setPolicyGroup] = useState<PolicyGroup>(
    (window.localStorage.getItem("policyGroup") as PolicyGroup) ||
      "BEYON_STANDARD"
  );

  useEffect(() => {
    window.localStorage.setItem("policyGroup", policyGroup);
  }, [policyGroup]);

  return (
    <PolicyContext.Provider value={{ policyGroup, setPolicyGroup, getPolicy }}>
      {children}
    </PolicyContext.Provider>
  );

  function getPolicy(policyType: PolicyType) {
    return `${policyGroup}_${policyType}`;
  }
}

export const PolicyContext = createContext<PolicyStateType>({
  policyGroup: "",
  setPolicyGroup: (policyType: string) => {},
  getPolicy: (policyType: PolicyType) => "",
});
