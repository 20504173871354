import React, { useContext, useMemo } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Stack } from "react-bootstrap";
import { useAuthFlow } from "../hooks/useAuthFlow";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { PolicyContext } from "../context/policyContext";

export function PageLayout(props) {
  const { signin, editProfile, logout } = useAuthFlow();

  const policyContext = useContext(PolicyContext);

  const theme = useMemo(() => {
    let color = "#8c0e84"; // default BEYON Purple
    if (policyContext.policyGroup === "BEYON_NZGD") color = "#449d44";

    return createTheme({
      palette: {
        primary: { main: color },
      },
    });
  }, [policyContext.policyGroup]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="transparent">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              ></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                B2C Demo Application
              </Typography>{" "}
              <UnauthenticatedTemplate>
                <Button variant="contained" color="primary" onClick={signin}>
                  Sign in
                </Button>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <Stack gap={1} direction="horizontal">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={editProfile}
                  >
                    Edit Profile
                  </Button>
                  <Button variant="contained" color="primary" onClick={logout}>
                    Sign out
                  </Button>
                </Stack>
              </AuthenticatedTemplate>
            </Toolbar>
          </AppBar>
        </Box>
        <div className="profileContent">{props.children}</div>
      </ThemeProvider>
    </>
  );
}
