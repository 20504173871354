import { useMsal } from "@azure/msal-react";
import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export function ClaimsTable() {
  const { accounts } = useMsal();
  const theme = useTheme();

  const claims = accounts[0]?.idTokenClaims as { [key: string]: string };

  return (
    <Stack alignItems="flex-start" gap={1}>
      <Typography variant="h4">Check out your ID Token claims</Typography>
      <table style={{ textAlign: "left", margin: "auto" }}>
        <tbody>
          {Object.keys(claims || {}).map((key) => (
            <tr key={key}>
              <td style={{ paddingRight: theme.spacing(2) }}>
                <Typography variant="subtitle1">{key}</Typography>
              </td>
              <td>
                <Typography>{claims[key]}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Stack>
  );
}
