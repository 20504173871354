import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { PolicyContext, PolicyGroup } from "../context/policyContext";

export function PolicySwitchController() {
  const policyContext = useContext(PolicyContext);

  return (
    <Stack alignItems="flex-start" gap={1}>
      <Typography variant="h4">Select which flow you want to test</Typography>
      <Select
        size="small"
        variant="outlined"
        value={policyContext.policyGroup}
        onChange={onChange}
      >
        <MenuItem value="BEYON_STANDARD">BEYON_STANDARD</MenuItem>
        <MenuItem value="BEYON_NZGD">BEYON_NZGD</MenuItem>
      </Select>
    </Stack>
  );

  function onChange(event: SelectChangeEvent<string>) {
    policyContext.setPolicyGroup(event.target.value as PolicyGroup);
  }
}
