import React, { useState } from "react";

import { Alert, Button, Snackbar, Stack, Typography } from "@mui/material";

import { useAuthFlow } from "../hooks/useAuthFlow";
import { useEnvConfig } from "../hooks/useEnvConfig";

export function AuthVerificationController() {
  const [snackState, setSnackState] = useState<{
    open: boolean;
    message?: string;
    color?: "success" | "error";
  }>({ open: false });

  const apiRoute = useEnvConfig().apiRoute;
  const { getToken } = useAuthFlow();

  function postInvitation(): void {
    getToken().then((token) => {
      fetch(`${apiRoute}/identity`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200)
          setSnackState({
            open: true,
            message: "You are allowed to fetch Beyon APIs",
            color: "success",
          });
        else
          setSnackState({
            open: true,
            message: `Failed to fetch Beyon APIs ${response.status}`,
            color: "error",
          });
      });
    });
  }

  return (
    <>
      <Stack alignItems="flex-start" gap={1}>
        <Typography variant="h4">
          Verify you are allowed to fetch Beyon APIs
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={postInvitation}
        >
          Verify
        </Button>
      </Stack>
      <Snackbar
        open={snackState.open}
        autoHideDuration={6000}
        onClose={() => setSnackState({ open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackState({ open: false })}
          severity={snackState.color}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackState.message}
        </Alert>
      </Snackbar>
    </>
  );
}
