export type EnvType = "localhost" | "Testing" | "Production";

export type EnvConfig = {
  env: EnvType;
  apiRoute: string;
  clientId: string;
  domain: string;
};

/*
    This is a loose strategy to deal with env variables so we don't have to deal with .env files.
    This is not a good practice for a production application, but it's good enough for the demo purpose.
*/
export function useEnvConfig(): EnvConfig {
  const env = getEnv();

  switch (env) {
    case "localhost":
      return {
        // uncomment the next line and comment the following line to use the test API
        // apiRoute: "https://api.test.beca.digital/api/b2c-t01/data/v1",
        env,
        apiRoute: "https://localhost:7186",

        // (Same as Testing)
        clientId: "1940ce31-83d6-42cb-aed0-18aa141c801f", // BEYON SPA
        domain: "login.test.beyon.global",
      };
    case "Testing":
      return {
        env,
        apiRoute: "https://api.test.beca.digital/b2c-t01/data/v1",
        clientId: "1940ce31-83d6-42cb-aed0-18aa141c801f", // BEYON SPA
        domain: "login.test.beyon.global",
      };
    case "Production":
      return {
        env,
        apiRoute: "https://api.beca.digital/b2c/data/v1",
        clientId: "7c5e7680-f453-486a-92cd-7738c1fb5e72", // BEYON SPA
        domain: "login.beyon.global",
      };
  }

  function getEnv(): EnvType {
    const origin = window.location.origin;

    if (origin.startsWith("http://localhost")) return "localhost";

    if (origin.startsWith("https://demo.login.test.beyon.global"))
      return "Testing";

    if (origin.startsWith("https://demo.login.beyon.global"))
      return "Production";
  }
}
