import React, { useState } from "react";

import {
  Alert,
  Button,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { useAuthFlow } from "../hooks/useAuthFlow";
import { useEnvConfig } from "../hooks/useEnvConfig";

export function InvitationController() {
  const [email, setEmail] = useState<string>("");
  const theme = useTheme();
  const [snackState, setSnackState] = useState<{
    open: boolean;
    message?: string;
    color?: "success" | "error";
  }>({ open: false });

  const { apiRoute } = useEnvConfig();
  const { getToken } = useAuthFlow();

  function postInvitation(): void {
    const encodedEmail = encodeURIComponent(email);
    const encodedOrigin = encodeURIComponent(window.location.origin);

    getToken().then((token) => {
      fetch(
        `${apiRoute}/identity/invitation?email=${encodedEmail}&redirectUrl=${encodedOrigin}&policy=beyon`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      ).then((response) => {
        if (response.status === 200)
          setSnackState({
            open: true,
            message: "Invitation sent",
            color: "success",
          });
        else
          setSnackState({
            open: true,
            message: `Failed to send invitation with status code ${response.status}`,
            color: "error",
          });
      });
    });
  }

  return (
    <>
      <Stack alignItems="flex-start" gap={1}>
        <Typography variant="h4">Invite a friend</Typography>
        <Stack gap={1} direction="row">
          <TextField
            size="small"
            label="Email"
            value={email}
            style={{ width: theme.spacing(60) }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={postInvitation}
          >
            Send Invitation
          </Button>
        </Stack>
      </Stack>
      <Snackbar
        open={snackState.open}
        autoHideDuration={6000}
        onClose={() => setSnackState({ open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackState({ open: false })}
          severity={snackState.color}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackState.message}
        </Alert>
      </Snackbar>
    </>
  );
}
