import {
  AuthenticationResult,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useContext } from "react";
import { useEnvConfig } from "./useEnvConfig";
import { PolicyContext, PolicyType } from "../context/policyContext";

export function useAuthFlow(): {
  signin: () => any;
  editProfile: () => Promise<void>;
  logout: () => Promise<void>;
  getToken: () => Promise<AuthenticationResult>;
} {
  const { domain } = useEnvConfig();

  const policyContext = useContext(PolicyContext);

  const apiScope = `https://${domain}/beyon-api/All`;

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    instance.addEventCallback((message) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        //this is the error code for password reset send by the policy redirection
        if (message.error.errorMessage.includes("AADB2C90118")) {
          instance.loginRedirect({
            authority: getAuthority("PASSWORD_RESET"),
            scopes: [apiScope],
          });
        }
      }
    });
  }, [instance]);

  return { signin, editProfile, logout, getToken };

  function signin() {
    if (inProgress !== InteractionStatus.None) return null;

    var promise = instance.loginRedirect({
      authority: getAuthority("SIGNUP_SIGNIN"),
      scopes: [apiScope],
      prompt: "login",
    });

    promise.catch((error) => {
      console.log(error);
    });
  }

  function editProfile() {
    if (inProgress !== InteractionStatus.None) return null;

    return instance.acquireTokenRedirect({
      authority: getAuthority("PROFILE_EDIT"),
      scopes: [apiScope],
    });
  }

  function logout() {
    return instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  }

  function getToken() {
    if (inProgress !== InteractionStatus.None) return null;

    const accessTokenRequest = {
      scopes: [apiScope],
      account: accounts[0],
      authority: getAuthority("SIGNUP_SIGNIN"),
    };

    return instance.acquireTokenSilent(accessTokenRequest);
  }

  function getAuthority(policyType: PolicyType) {
    const policyRouteBase = `https://${domain}/${domain}`;
    return `${policyRouteBase}/b2c_1a_${policyContext.getPolicy(policyType)}`;
  }
}
