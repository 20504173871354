import { Configuration as MsalConfiguration, LogLevel } from "@azure/msal-browser";
import { useEnvConfig } from "./useEnvConfig";

export function useAuthConfig(): MsalConfiguration{

    const envConfig = useEnvConfig();    

    return {
        auth: {
            clientId: envConfig.clientId,
            knownAuthorities: [envConfig.domain],
            authority: "", // Not configured here as this shall change based on which PolicyType is selected
            redirectUri: "/",
            navigateToLoginRequestUrl: true,
            postLogoutRedirectUri: "/",
        },
        cache: {
            cacheLocation: "localStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            // Uncomment to get more debug information in the logg
                            // console.info(message);
                            return;
                        case LogLevel.Verbose:
                            // Uncomment to get more debug information in the logg
                            // console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                }
            }
        }
    };
}